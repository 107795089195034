import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { AuthGuard, GuestGuard, Loader, RoleGuard } from "./components";

import MainLayout from "./layouts/MainLayout";
import {
  Messaging,
  TodoListMainLayout,
  HelpDeskMainLayout,
  SettingsMainLayout,
} from "./pages";
export const renderRoutes = (routes = []) => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};
export const routes = [
  {
    exact: true,
    path: "/login",
    guard: GuestGuard,
    component: lazy(() => import("./pages/Auth/Login/Login")),
  },
  {
    exact: true,
    path: "/confirm-account/:websiteId/:token",
    component: lazy(() => import("./pages/ConfirmAccount")),
  },
  {
    path: "/",
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: "/messaging",
        layout: Messaging,
        component: lazy(() => import("./pages/Messaging")),
      },
      {
        exact: true,
        path: "/messaging/:websiteID/:conversationID",
        layout: Messaging,
        component: lazy(() => import("./pages/Messaging")),
      },
      {
        path: "/todo-list",
        layout: TodoListMainLayout,
        routes: [
          {
            exact: true,
            guard: RoleGuard,
            path: "/todo-list/open",
            component: lazy(() => import("./pages/TodoList/Open")),
          },
          {
            exact: true,
            path: "/todo-list/assigned",
            component: lazy(() => import("./pages/TodoList/Assigned")),
          },
          {
            exact: true,
            guard: RoleGuard,
            path: "/todo-list/complete",
            component: lazy(() => import("./pages/TodoList/Complete")),
          },
          {
            exact: true,
            path: "/todo-list",
            component: () => <Redirect to="/todo-list/assigned" />,
          },
          {
            path: "*",
            component: () => <Redirect to="/todo-list" />,
          },
        ],
      },
      {
        path: "/help-desk",
        layout: HelpDeskMainLayout,
        routes: [
          {
            exact: true,
            path: "/help-desk/articles",
            component: lazy(() => import("./pages/HelpDesk/Articles")),
          },
          {
            exact: true,
            path: "/help-desk/articles/create",
            component: lazy(() =>
              import("./pages/HelpDesk/Articles/CreateArticle")
            ),
          },
          {
            exact: true,
            path: "/help-desk/draft/create",
            component: lazy(() =>
              import("./pages/HelpDesk/Articles/CreateArticle")
            ),
          },
          {
            exact: true,
            path: "/help-desk/visible/create",
            component: lazy(() =>
              import("./pages/HelpDesk/Articles/CreateArticle")
            ),
          },
          {
            exact: true,
            path: "/help-desk/articles/edit",
            component: lazy(() =>
              import("./pages/HelpDesk/Articles/CreateArticle")
            ),
          },
          {
            exact: true,
            path: "/help-desk/articles/show",
            component: lazy(() =>
              import("./pages/HelpDesk/Articles/showArticle")
            ),
          },
          {
            exact: true,
            path: "/help-desk/draft/edit",
            component: lazy(() =>
              import("./pages/HelpDesk/Articles/CreateArticle")
            ),
          },
          {
            exact: true,
            path: "/help-desk/draft/show",
            component: lazy(() =>
              import("./pages/HelpDesk/Articles/showArticle")
            ),
          },
          {
            exact: true,
            path: "/help-desk/visible/edit",
            component: lazy(() =>
              import("./pages/HelpDesk/Articles/CreateArticle")
            ),
          },
          {
            exact: true,
            path: "/help-desk/visible/show",
            component: lazy(() =>
              import("./pages/HelpDesk/Articles/showArticle")
            ),
          },
          {
            exact: true,
            path: "/help-desk/draft",
            component: lazy(() => import("./pages/HelpDesk/Draft")),
          },
          {
            exact: true,
            path: "/help-desk/visible",
            component: lazy(() => import("./pages/HelpDesk/Visible")),
          },
       
          {
            exact: true,
            path: "/help-desk",
            component: () => <Redirect to="/help-desk/articles" />,
          },
          {
            path: "*",
            component: () => <Redirect to="/help-desk" />,
          },
        ],
      },
      {
        exact: true,
        path: "/analytics",
        component: lazy(() => import("./pages/Analytics")),
      },
      {
        exact: true,
        path: "/notifications",
        component: lazy(() => import("./pages/Notifications")),
      },
      {
        exact: true,
        path: "/archived",
        component: lazy(() => import("./pages/Archived")),
      },
      {
        exact: true,
        path: "/archived/:websiteID/:conversationID",
        component: lazy(() => import("./pages/Archived")),
      },
      {
        path: "/settings",
        layout: SettingsMainLayout,
        routes: [
          {
            exact: true,
            path: "/settings/account",
            component: lazy(() => import("./pages/Settings/Account")),
          },
          {
            exact: true,
            path: "/settings/notification",
            component: lazy(() => import("./pages/Settings/Notification")),
          },
          {
            exact: true,
            guard: RoleGuard,
            path: "/settings/users",
            component: lazy(() => import("./pages/Settings/Users")),
          },
          {
            exact: true,
            guard: RoleGuard,
            path: "/settings/website-configuration",
            component: lazy(() => import("./pages/Settings/BullColorPicker")),
          },
          {
            exact: true,
            guard: RoleGuard,
            path: "/settings/chatbot-configuration",
            component: lazy(() => import("./pages/Settings/BotConfig")),
          },
          {
            exact: true,
            guard: RoleGuard,
            path: "/settings/departments",
            component: lazy(() => import("./pages/Settings/Departments")),
          },
          {
            exact: true,
            path: "/settings/shortcut-message",
            component: lazy(() => import("./pages/Settings/ShortcutMessages")),
          },

          {
            exact: true,
            path: "/settings/clients",
            component: lazy(() => import("./pages/Settings/Clients")),
          },

          {
            exact: true,
            path: "/settings",
            component: () => <Redirect to="/settings/account" />,
          },
          {
            path: "*",
            component: () => <Redirect to="/settings" />,
          },
        ],
      },
      {
        exact: true,
        path: "/bot",
        component: lazy(() => import("./pages/Bot")),
      },

      {
        exact: true,
        path: "/",
        component: () => <Redirect to="/messaging" />,
      },
      {
        path: "*",
        component: () => <Redirect to="/messaging" />,
      },
    ],
  },
];

export default routes;
